

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";


import {
  OTHER_INCOME_SOURCE,
  ASSET_TYPE,
  OTHER_ASSET,
  LIABILITIES_TYPE,
  GIFT_SOURCE_TYPE,
  GIFT_TYPE,
  REAL_ESTATE_MORTGAGE_TYPE,
  LIABILITIES_EXPENSES,
  REAL_ESTATE_STATUS_TYPE,
  MARITAL_STATUS,
  TITLE_HOLD_TYPE,
  ANOTHER_PROPERTY_TYPE,
  RESIDENCE_BASIS_TYPE,
  DEMOGRAPHY_CHANNEL_TYPE,
  CITIZEN_SHIP
} from "@/constants";
import stores from "@/store/store";
const store:any  = stores;
import { Name } from "../../../models/pos/common.model";
import {
  BorrowerDetails,
  CurrentEmployment,
  IncomeSource,
  DependentsDetails,
  Dependents,
  AddressTypeDetail,
  DateDuration,
  AppliedService
} from "../../../models/pos/borrower-info.model";
import {
  Assets,
  OtherAssets,
  Liabilities,
  OtherLiabilitiesAndExpenses,
  Properties
} from "@/models/pos/financial-info.model";

import { AdditionalLoans, Gift } from "@/models/pos/loan-and-property.model";

@Component
export default class URLAComponent extends Vue {
  public get stateList() {
    return store.state.sessionObject.states;
  }
  public loanTxnId: any = "";
  public otherIncomeSource = OTHER_INCOME_SOURCE;
  public demographicChannelType = DEMOGRAPHY_CHANNEL_TYPE;
  public assetType = ASSET_TYPE;
  public otherAssetType = OTHER_ASSET;
  public titleHoldType = TITLE_HOLD_TYPE;
  public anotherPropertyType = ANOTHER_PROPERTY_TYPE;
  public maritalStatus = MARITAL_STATUS;
  public residenceType = RESIDENCE_BASIS_TYPE;
  public liabilitiesType = LIABILITIES_TYPE;
  public otherLiabilitiesExpenses = LIABILITIES_EXPENSES;
  public realEstateStatusType = REAL_ESTATE_STATUS_TYPE;
  public realEstateMortgageType = REAL_ESTATE_MORTGAGE_TYPE;
  public citizenShip = CITIZEN_SHIP;
  public giftType = GIFT_TYPE;
  public giftSourceType = GIFT_SOURCE_TYPE;
  public borrowerName = null;
  public index = 0;
  public selectedIndex = "borrower";
  public userType = "borrowerInfo";
  public addressLOI = this.posDataStore.loanOriginatorInfo.date;
  public currentDependentArray = new Array();
  public get posConfig() {
    return this.$store.state.posSessionConfig;
  }
  public set posConfig(data) {
    this.$store.state.posSessionConfig = data;
  }

  public get haveUrlaFormDataValue() {
    return this.$store.state.haveUrlaFormDataValue;
  }

  public get posDataStore() {
    return this.$store.state.urlaFormData;
  }

  public set posDataStore(data) {
    this.$store.state.urlaFormData = data;
  }

  public years(start: number, end: number) {
    let years = [];
    for (let i = end; i > start; i--) {
      years.push(i);
    }
    return years;
  }

  get days() {
    let days = [];
    for (let i: any = 1; i < 32; i++) {
      i = i < 10 ? "0" + i : i;

      days.push(i);
    }
    return days;
  }

  get months() {
    let months = [];
    for (let i: any = 1; i < 13; i++) {
      i = i < 10 ? "0" + i : i;
      months.push(i);
    }
    return months;
  }
  get yearsPeriod() {
    let period = [];
    for (let i: any = 1; i < 101; i++) {
      i = i < 10 ? "0" + i : i;
      period.push(i);
    }
    return period;
  }

  toLetters(num) {
    "use strict";
    var mod = num % 26,
      pow = (num / 26) | 0,
      out = mod ? String.fromCharCode(64 + mod) : (--pow, "Z");
    return pow ? this.toLetters(pow) + out : out;
  }

  public initializeArray(type) {
    let initTypes = {
      assets: new Assets(),
      otherAsset: new OtherAssets(),
      Liabilities: new Liabilities(),
      OtherLiabilitiesAndExpenses: new OtherLiabilitiesAndExpenses(),
      AdditionalLoans: new AdditionalLoans(),
      Gifts: new Gift(),
      IncomeSource: new IncomeSource(),
      Properties: new Properties(),
      alternateNames: new Name(),
      coBorrowerInfo: new BorrowerDetails(),
      Dependents: new DependentsDetails(),
      currentEmployment: new CurrentEmployment(),
      Dependent: new Dependents(),
      ExpirationDate: new DateDuration(),
      AppliedService: new AppliedService()
    };
    return initTypes[type];
  }

  public async submitData() {
    let loader = this.$loading.show();
    try {
      let response = await Axios.post(
        BASE_API_URL + "pos/savePosData",
        this.posDataStore,
        {
          params: {
            loanTxnId: this.loanTxnId
          }
        }
      );
      loader.hide();
      this.$snotify.success("Information saved successfully");
    } catch (error) {
      loader.hide();
      this.$snotify.error(
        "We are unable to save your details. Please try contacting the administrator."
      );
    }
  }

  public updateDeclarationCitizenShip(selectedCitizenShip) {
    if (selectedCitizenShip.mismoValue == "USCitizen") {
      this.posDataStore.declaration[this.userType][this.index].isUSCitizen =
        "true";
      this.posDataStore.declaration[this.userType][
        this.index
      ].isPermanentResidentAlien = "false";
    } else {
      if (selectedCitizenShip.mismoValue == "PermanentResidentAlien") {
        this.posDataStore.declaration[this.userType][this.index].isUSCitizen =
          "false";
        this.posDataStore.declaration[this.userType][
          this.index
        ].isPermanentResidentAlien = "true";
      } else {
        this.posDataStore.declaration[this.userType][this.index].isUSCitizen =
          "false";
        this.posDataStore.declaration[this.userType][
          this.index
        ].isPermanentResidentAlien = "false";
      }
    }
  }

  public async updateSateLicenseId(userId, modelName, stateLicenseId) {
    let loader = this.$loading.show();
    try {
      let body = {
        userId: userId,
        modelName: modelName,
        stateLicenseId: stateLicenseId
      };
      let response = await Axios.post(
        BASE_API_URL + "pos/updateSateLicenseId",
        body);
      await this.getUrlaData();
      loader.hide();
    } catch (error) {
      loader.hide();
      this.$snotify.error(
        "We are unable to save your details. Please try contacting the administrator."
      );
    }
  }
  public updateBinding() {
    this.userType =
      this.selectedIndex == "borrower" ? "borrowerInfo" : "coBorrowerInfo";
    this.index =
      this.userType == "borrowerInfo" ? 0 : parseInt(this.selectedIndex);
    this.$modal.hide("SelectUrlaForm");
  }

  async dependentsChange() {
    let valid = await this.$validator.validateAll();
    if (!valid) return;
    let ageArr = (this.posDataStore.borrowerInfo[this.userType][
      this.index
    ].personalInfo.maritalStatus.dependents.details = JSON.parse(
      JSON.stringify(this.currentDependentArray)
    ));
    this.posDataStore.borrowerInfo[this.userType][
      this.index
    ].personalInfo.maritalStatus.dependents.ages = ageArr.map(nd => nd.age);
    this.posDataStore.borrowerInfo[this.userType][
      this.index
    ].personalInfo.maritalStatus.dependents.number = ageArr.length;
    this.$modal.hide("AddDependents");
    this.currentDependentArray = [];
  }

  viewModal(modalName) {
    if (modalName == "AddDependents") {
      this.currentDependentArray = [];
      if (this.userType == "borrowerInfo") {
        this.currentDependentArray = JSON.parse(
          JSON.stringify(
            this.posDataStore.borrowerInfo.borrowerInfo[0].personalInfo
              .maritalStatus.dependents.details
          )
        );
      } else {
        this.currentDependentArray = JSON.parse(
          JSON.stringify(
            this.posDataStore.borrowerInfo.coBorrowerInfo[this.index]
              .personalInfo.maritalStatus.dependents.details
          )
        );
      }
    }
    this.$modal.show(modalName);
  }

  public get currentAddressDurationGreaterThanTwoYear() {
    let obj = this.posDataStore.borrowerInfo[this.userType][this.index]
      .personalInfo.address.currentAddress.duration;
    let totalMonth = 0;
    if (obj.years && obj.months) {
      totalMonth = parseInt(obj.years) * 12 + parseInt(obj.months);
    } else if (obj.years && !obj.months) {
      totalMonth = parseInt(obj.years) * 12;
    } else if (!obj.years && obj.months) {
      totalMonth = parseInt(obj.months);
    }
    if (totalMonth >= 24) {
      this.posDataStore.borrowerInfo[this.userType][
        this.index
      ].personalInfo.address.formerAddress = new AddressTypeDetail();
    }
    return totalMonth >= 24 ? true : false;
  }

  getSum(arr, variable) {
    arr = arr.filter(item =>
      variable
        ? this.$lodash.get(item, variable)
        : item != undefined && variable
        ? this.$lodash.get(item, variable)
        : item != "" && variable
        ? this.$lodash.get(item, variable)
        : item != null && variable
        ? this.$lodash.get(item, variable)
        : item
    );
    arr = arr.map(a => (variable ? this.$lodash.get(a, variable) : a));
    arr = arr.reduce(function(a, b) {
      return parseFloat(a) + parseFloat(b);
    }, 0);
    return arr;
  }
  getGrossMonthlyIncome(index) {
    let obj = this.posDataStore.borrowerInfo[this.userType][this.index]
      .currentEmployment[index].grossMonthlyIncome;
    let arr = [];
    for (const key in obj) {
      if (
        key != "netRentalIncome" &&
        obj.hasOwnProperty(key) &&
        obj[key].monthlyIncome != undefined &&
        obj[key].monthlyIncome != "" &&
        obj[key].monthlyIncome != null
      )
        arr.push(obj[key].monthlyIncome);
    }

    arr = arr.reduce(function(a, b) {
      return parseFloat(a) + parseFloat(b);
    }, 0);
    return arr;
  }
  public async getUrlaData() {
    await this.$store.dispatch("fetchPosData", { id: this.loanTxnId });
    this.borrowerName =
      this.posDataStore.borrowerInfo.borrowerInfo[0].personalInfo.name
        .firstName +
      " " +
      this.posDataStore.borrowerInfo.borrowerInfo[0].personalInfo.name.lastName;
  }

  async mounted() {
    // let loader = this.$loading.show();
    this.loanTxnId = this.$route.query.id;
    await this.getUrlaData();
    if (this.haveUrlaFormDataValue) {
      // loader.hide();
      if (
        this.posDataStore.borrowerInfo.borrowerInfo[0].personalInfo
          .hasAdditionalBorrower == "true"
      ) {
        this.$modal.show("SelectUrlaForm");
      }
    }
  }
  beforeDestroy() {
    this.$store.dispatch("resetPosData");
  }
}
